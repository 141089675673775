// src/components/Guests/Grid.tsx
import React, { FC } from 'react';
import { Col, Container, Row } from 'react-grid-system';
import styled from 'styled-components';
import GuestsBio from './GuestsBio';
import { GuestData } from './types';

interface Props {
  data: GuestData[];
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

const Section = styled.section`
  margin: 0 auto;
  max-width: 1440px;
  padding: 1rem;
`;

const JobTypeSection = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 2rem 0;
  gap: 2rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

/* Updated Sidebar styling for mobile responsiveness */
const Sidebar = styled.div`
  flex: 0 0 200px;
  background-color: #f0f0f0;
  padding: 1rem;
  border-radius: 8px;
  text-align: left;
  position: -webkit-sticky;
  position: sticky;
  top: calc(83px + 1rem);
  z-index: 10;

  @media (max-width: 768px) {
    /* Remove sticky behavior and expand to full width on mobile */
    position: static;
    flex: none;
    width: 100%;
    margin-bottom: 1rem;
  }
`;

const SidebarHeader = styled.h2`
  font-size: 1.75rem;
  font-weight: 700;
  color: #990000;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ContentArea = styled.div`
  flex: 1;
`;

const Card = styled.div`
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const ImageContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: 100%; /* Maintains a square aspect ratio */
  overflow: hidden;
`;

const StyledImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 85%;  /* 15% smaller than the container */
  height: 85%;
  transform: translate(-50%, -50%);
  object-fit: cover;
`;

const Grid: FC<Props> = ({
  data,
  xs = 12,
  sm = 6,
  md = 4,
  lg = 3,
  xl = 3,
}) => {
  // Group guests by their jobType property
  const groups = data.reduce((acc, item) => {
    const jobType = item.jobType;
    if (!acc[jobType]) {
      acc[jobType] = [];
    }
    acc[jobType].push(item);
    return acc;
  }, {} as Record<string, GuestData[]>);

  return (
    <Section>
      {Object.entries(groups).map(([jobType, guests]) => (
        <JobTypeSection key={jobType}>
          <Sidebar>
            <SidebarHeader>{jobType}</SidebarHeader>
          </Sidebar>
          <ContentArea>
            <Container fluid>
              <Row gutterWidth={16}>
                {guests.map((item) => (
                  <Col
                    key={item.id}
                    xs={xs}
                    sm={sm}
                    md={md}
                    lg={lg}
                    xl={xl}
                    style={{ marginBottom: '1rem' }}
                  >
                    <Card>
                      <ImageContainer>
                        <StyledImage
                          src={item.image}
                          alt={`${item.name} portrait`}
                        />
                      </ImageContainer>
                      <GuestsBio
                        name={item.name}
                        title={item.title}
                        bio={item.bio || ''}
                      />
                    </Card>
                  </Col>
                ))}
              </Row>
            </Container>
          </ContentArea>
        </JobTypeSection>
      ))}
    </Section>
  );
};

export default Grid;
