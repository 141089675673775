import React, { FC } from 'react';
import { Col, Container, Row, useScreenClass } from 'react-grid-system';
import styled from 'styled-components';

import AlumniBio from './AlumniBio';
import { AlumniData } from './data';

interface AlumniGridProps {
  data: AlumniData[];
}

const Section = styled.section<{ isDesktop: boolean }>`
  margin: 0 auto;
  max-width: 1440px;
  padding: 2rem 1rem;
`;

const AlumniGrid: FC<AlumniGridProps> = ({ data }) => {
  const screenClass = useScreenClass();
  const isDesktop = ['lg', 'xl'].includes(screenClass);

  return (
    <Section isDesktop={isDesktop}>
      <Container>
        {/* 
          'Row' can be given 'style' or 'gutterWidth' for spacing. 
          We can also do align='start' to ensure top alignment. 
        */}
        <Row gutterWidth={24} align="start">
          {data.map((item) => (
            <Col 
              key={item.id} 
              xs={12}  // 1 column on extra small 
              sm={6}   // 2 columns on small 
              md={6}   // 2 columns on medium 
              lg={4}   // 3 columns on large 
              xl={4}   // 3 columns on extra large 
              style={{ marginBottom: '2rem' }}
            >
              <AlumniBio
                name={item.name}
                title={item.title}
                bio={item.bio}
                image={item.image}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

export default AlumniGrid;
