import React, { FC } from 'react'
import { Grid } from '../components/Events'
import { EVENTS_DATA } from '../components/Events/data'
import { SecondaryHero } from '../components/shared'

const Events: FC = () => {
  return (
    <>
      <SecondaryHero>Past Events</SecondaryHero>
      <Grid data={EVENTS_DATA} />
    </>
  )
}

export default Events
