// src/components/Guests/GuestsBio.tsx
import React from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

interface Props {
  name: string;
  title: string;
  bio: string;
}

const Content = styled.div`
  padding: 0.75rem 1rem;
  text-align: left;
`;

const Name = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
  margin: 0 0 0.25rem 0;
  color: #1c1e1f;
`;

const Title = styled.p`
  font-size: 0.85rem;
  margin: 0 0 0.5rem 0;
  color: #4b4f56;
`;

const BioText = styled.div`
  font-size: 0.85rem;
  line-height: 1.4;
  color: #1c1e1f;
`;

const GuestsBio = ({ name, title, bio }: Props) => {
  return (
    <Content>
      <Name>{name}</Name>
      <Title>{title}</Title>
      <ReactMarkdown
        linkTarget="_blank"
        renderers={{
          paragraph: ({ children }: { children: React.ReactNode }) => (
            <BioText>{children}</BioText>
          ),
        }}
      >
        {bio}
      </ReactMarkdown>
    </Content>
  );
};

export default GuestsBio;
