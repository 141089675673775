import React, { FC } from 'react'
import { useScreenClass } from 'react-grid-system'
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import Slide from './Slide'
import FACULTY from '../../assets/networking.jpg'
import SCHEDULE from '../../assets/schedule.jpg'
import styled from 'styled-components'

const Section = styled.section`
  .carousel .control-prev.control-arrow:before {
    border-right: 8px solid #000;
  }
  .carousel .control-next.control-arrow:before {
    border-left: 8px solid #000;
  }
`

const Slider: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  // On mobile, we display the background images in each <div>.
  const mobileStyle = (variant: string) => ({
    backgroundImage: `url('${variant === 'schedule' ? SCHEDULE : FACULTY}')`,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
  })

  // On desktop, we give it a narrower width, center it, and add a drop shadow.
  // IMPORTANT: Remove the negative margin so it doesn't overlap your hero.
  const desktopStyle = {
    width: '85%',
    filter: 'drop-shadow(0px 9px 13px rgba(0, 0, 0, 0.31))',
    margin: '2rem auto', // use a positive margin to space it down from above
    backgroundColor: 'white',
    borderRadius: '2px',
  }

  return (
    <Section style={isDesktop ? desktopStyle : undefined}>
      <Carousel
        interval={3000}
        infiniteLoop
        showStatus={false}
        stopOnHover={true}
        showThumbs={false}
        showArrows={true}
      >
        <div style={!isDesktop ? mobileStyle('schedule') : { display: 'flex' }}>
          <Slide variant="faculty" />
        </div>
        <div style={!isDesktop ? mobileStyle('faculty') : { display: 'flex' }}>
          <Slide variant="schedule" />
        </div>
      </Carousel>
    </Section>
  )
}

export default Slider
