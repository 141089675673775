import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import styled from 'styled-components';

interface AlumniBioProps {
  name: string;
  title: string;
  bio?: string;
  image: string; // now 640x640
}

/* Modern card design */
const Card = styled.article`
  background-color: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

  transition: transform 0.3s, box-shadow 0.3s;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
  }
`;

const Image = styled.img`
  /* Scales the image to fit the card width, 
     preserving the square ratio (640x640). */
  width: 100%;
  height: auto;
  display: block;

  /* Optional: Add some corner rounding. */
  border-bottom: 1px solid #ddd;
`;

const Content = styled.div`
  /* Some spacing around your text. */
  padding: 1.25rem;
`;

const Name = styled.h3`
  margin: 0;
  font-size: 1.2rem;
  font-weight: 700;
  color: #222;
`;

const Title = styled.p`
  margin: 0.25rem 0 0.75rem;
  font-size: 0.95rem;
  color: #555;
`;

const Bio = styled(ReactMarkdown)`
  font-size: 0.9rem;
  line-height: 1.5;
  color: #333;

  p {
    margin-bottom: 1em;
  }

  a {
    color: #990000;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const AlumniBio: FC<AlumniBioProps> = ({ name, title, bio, image }) => (
  <Card>
    <Image src={image} alt={`${name} headshot`} />
    <Content>
      <Name>{name}</Name>
      <Title>{title}</Title>
      {bio && <Bio linkTarget="_blank">{bio}</Bio>}
    </Content>
  </Card>
);

export default AlumniBio;
