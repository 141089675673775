import Event1 from '../../assets/events/2021.jpg'
import Event2 from '../../assets/events/2022.jpg'
import Event3 from '../../assets/events/2023.png'
import Event4 from '../../assets/events/2024.png'
import Event5 from '../../assets/events/2025.png'
import Event6 from '../../assets/events/LAEvent.jpg'


export const EVENTS_DATA = [
    {
    description:
        'Story + Craft: An Evening for Audio Storytellers at Western Sound in LA',
    image: Event6,
    },  
    {
    description:
      'Industry Insights from: Karen Given, Keisha "TK" Dutes, Yowei Shaw, Amanda McLoughlin',
    image: Event5,
  },
  {
    description:
      'Industry Insights from: Kerry Donahue, Nichole Hill',
    image: Event2,
  },
  {
    description: 'With Special Guest & Host of Dead Eyes: Connor Ratliff',
    image: Event3,
  },
  {
    description: 'Industry Insights from: Arielle Nissenblatt, Eric Eddings, Amanda McLoughlin, Eric Silver',
    image: Event4,
  },
  {
    description:
      'Panelists Include: Hrishikesh Hirway, Ophira Eisenberg, Amanda Knox, Cher Vincent, Josh Gondelman',
    image: Event1,
  },
]
