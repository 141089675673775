import React, { FC } from 'react'
import { useScreenClass } from 'react-grid-system'
import styled, { keyframes } from 'styled-components'
import HERO from '../../assets/QAbanner.svg'
import {
  KATHIE,
  KERRY,
  ZACH,
  AMANDA,
  CHRIS,
  CATHERINE
} from '../../assets/faculty'

// Faculty data for the bubbles
const facultyData = [
  {
    image: KATHIE,
    name: 'Kathleen Russo',
    title: 'Program Director',
    bio: 'Producer for HTT with Alec Baldwin, You and Me Both with Hillary Clinton, and more.'
  },
  {
    image: KERRY,
    name: 'Kerry Donahue',
    title: 'Lead Instructor',
    bio: 'Former Executive Prodducer at WNYC and Director of Training at PRX'
  },
  {
    image: CHRIS,
    name: 'Chris O\'Keefe',
    title: 'Teaching Assistant',
    bio: 'Creative Director/Founder at OK Creative'
  },
  {
    image: AMANDA,
    name: 'Amanda McLoughlin',
    title: 'Professor',
    bio: 'CEO at Multitude Productions'
  },
  {
    image: ZACH,
    name: 'Zach McNees',
    title: 'Audio Engineer Instructor',
    bio: 'Engineer on Unconfuse me with Bill Gates, Song Exploder, and more.'
  },
  {
    image: CATHERINE,
    name: 'Catherine Burns',
    title: 'Professor',
    bio: 'Former Artistic Director at The Moth'
  }
]

// Marquee animation for continuous scroll
const marqueeAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
`

const HeroContainer = styled.section<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: ${(props) => (props.isDesktop ? '3rem 1rem' : '2rem 1rem')};
  margin: 0 auto;
  background-color: #6B000D;
`

// Only the hero image container remains
const ImageContainer = styled.div<{ isDesktop: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const ImageWrapper = styled.div<{ isDesktop: boolean }>`
  flex: 1;
`

const BannerImage = styled.img<{ isDesktop: boolean }>`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: ${(props) => (props.isDesktop ? '900px' : '100%')};
  max-height: 600px;
  object-fit: contain;
`

// Faculty marquee
const FacultyMarquee = styled.div`
  overflow: hidden;
  width: 100%;
  padding: 1rem 0;
`

// Use max-content to ensure the width is based on the bubble contents,
// so the duplicated bubbles scroll fully on mobile.
const FacultyTrack = styled.div`
  display: flex;
  width: max-content;
  animation: ${marqueeAnimation} 20s linear infinite;
`

const BubbleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1rem;
  min-width: 150px;
  /* Subtle semi-transparent background */
  background: rgba(255, 255, 255, 0.15);
  padding: 0.5rem;
  border-radius: 12px;
`

const BubbleImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 0.5rem;
  border: 2px solid rgba(255, 255, 255, 0.8);
`

const FacultyName = styled.h3`
  font-size: 1.1rem;
  margin: 0.3rem 0 0.2rem;
  color: #ffffff;
`

const FacultyTitle = styled.h4`
  font-size: 0.95rem;
  margin: 0.2rem 0;
  color: #f0f0f0;
`

const FacultyBio = styled.p`
  font-size: 0.8rem;
  margin: 0;
  text-align: center;
  max-width: 180px;
  color: #e0e0e0;
`

const HeroButton = styled.a<{ size: string }>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${(props) => (props.size === 'lg' ? '1.25rem 3.25rem' : '0.75rem 2rem')};
  border: 2px solid ${(props) => props.theme.colors.red};
  border-radius: 8px;
  background-color: white;
  color: ${(props) => props.theme.colors.red};
  font-size: ${(props) => (props.size === 'lg' ? '18px' : '14px')};
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  text-decoration: none;
  /* Reduced top margin and added bottom margin */
  margin-top: 1rem;
  margin-bottom: 2rem;
  z-index: 1;

  &:hover {
    background-color: ${(props) => props.theme.colors.red};
    color: white;
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(255, 0, 0, 0.5);
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  }
`

const Hero: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  return (
    <HeroContainer isDesktop={isDesktop}>
      <ImageContainer isDesktop={isDesktop}>
        <ImageWrapper isDesktop={isDesktop}>
          <BannerImage src={HERO} alt="Job Banner" isDesktop={isDesktop} />
        </ImageWrapper>
      </ImageContainer>
      <HeroButton
        href="https://stonybrook.zoom.us/meeting/register/nnKrYlxfQOe7paAAVYvzcg"
        size={isDesktop ? 'lg' : 'sm'}
        target="_blank"
        rel="noopener noreferrer"
      >
        CLICK HERE TO RSVP
      </HeroButton>
      <FacultyMarquee>
        <FacultyTrack>
          {facultyData.concat(facultyData).map((faculty, index) => (
            <BubbleContainer key={index}>
              <BubbleImage src={faculty.image} alt={faculty.name} />
              <FacultyName>{faculty.name}</FacultyName>
              <FacultyTitle>{faculty.title}</FacultyTitle>
              <FacultyBio>{faculty.bio}</FacultyBio>
            </BubbleContainer>
          ))}
        </FacultyTrack>
      </FacultyMarquee>
    </HeroContainer>
  )
}

export default Hero
