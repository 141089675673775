import React, { FC, useState, useRef, useEffect } from 'react'
import Logo from '../../assets/main_logo.jpg'
import mobileLogo from '../../assets/StonyLogo.png'
import { Row, useScreenClass, Hidden } from 'react-grid-system'
import styled from 'styled-components'
import { Button, Hamburger, Menu } from '.'
import { useOnClickOutside } from './Hamburger'
import { NavLink, Link } from 'react-router-dom'
import { NavHashLink } from 'react-router-hash-link'

const LinkWrapper = styled.div`
  .nav-link {
    color: ${(props) => props.theme.colors.black};
    font-size: 18px;
    margin: 0 12px;
    cursor: pointer;
    position: relative;
    transition: color 0.3s ease;
    &:hover {
      color: #990000;
    }
  }

  a:last-child {
    margin-left: 12px;
  }
`

// Styled component for the dropdown toggle that includes a caret
const DropdownToggle = styled.span<{ open: boolean }>`
  display: inline-flex;
  align-items: center;
  &::after {
    content: ' ▼';
    font-size: 12px;
    margin-left: 4px;
    transition: transform 0.3s ease;
    transform: ${(props) => (props.open ? 'rotate(180deg)' : 'rotate(0)')};
  }
`

const Dropdown = styled.div`
  position: relative;
  display: inline-block;
`

const DropdownContent = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 4px;
  min-width: 180px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.3s ease, transform 0.3s ease, visibility 0.3s;
  
  ${Dropdown}:hover & {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }
  
  a {
    display: block;
    padding: 12px 16px;
    color: ${(props) => props.theme.colors.black};
    text-decoration: none;
    font-size: 16px;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #f7f7f7;
    }
  }
`

const Nav = styled.nav<{ scrollY: number; isDesktop: boolean }>`
  padding: 8px 3rem;
  background-color: white;
  transition: position 0.8s;
  position: ${(props) =>
    props.isDesktop && props.scrollY > 83 ? 'fixed' : ''};
  top: ${(props) => (props.isDesktop && props.scrollY > 83 ? '0' : '')};
  left: ${(props) => (props.isDesktop && props.scrollY > 83 ? '0' : '')};
  z-index: ${(props) => (props.isDesktop && props.scrollY > 83 ? '99' : '')};
  width: ${(props) =>
    props.isDesktop && props.scrollY > 83 ? 'calc(100% - 6rem)' : ''};
`

const activeStyles = {
  color: '#990000',
  fontWeight: 700,
  letterSpacing: '1px',
  transition: 'font-weight 0.3s',
}

const NavBar: FC = () => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)
  const [openMenu, setOpenMenu] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [yPos, setYPos] = useState(0)
  const burger = useRef(null)
  useOnClickOutside(burger, () => setOpenMenu(false))

  useEffect(() => {
    const handleScroll = () => setYPos(window.scrollY)
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <Nav scrollY={yPos} isDesktop={isDesktop}>
      <Row justify="between" align="center">
        <Link to="/">
          {['lg', 'xl'].includes(screenClass) ? (
            <img
              src={Logo}
              alt="Stony Brook University Logo"
              style={{ width: '80%' }}
            />
          ) : (
            <img
              src={mobileLogo}
              alt="Stony Brook University Logo"
              style={{ width: '90px' }}
            />
          )}
        </Link>
        <Hidden lg xl>
          <div ref={burger}>
            <Hamburger open={openMenu} setOpen={setOpenMenu} />
            <Menu open={openMenu} />
          </div>
        </Hidden>
        <Hidden xs sm md>
          <LinkWrapper>
            {/* About Us Dropdown */}
            <Dropdown
              onMouseEnter={() => setDropdownOpen(true)}
              onMouseLeave={() => setDropdownOpen(false)}
            >
              <DropdownToggle open={dropdownOpen} className="nav-link">
                About Us
              </DropdownToggle>
              <DropdownContent>
                <NavLink to="/faculty" className="nav-link" activeStyle={activeStyles}>
                  Faculty
                </NavLink>
                <a
                  href="https://docs.google.com/document/d/e/2PACX-1vQ8PIc9oa9DRRNByVriyNoZSRuim7Ew1yr1SBzhQ-XZlBRv4kd4q-I1KNZTh44F97Xe6RnK-yH_-Af1/pub"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="nav-link"
                >
                  Syllabus
                </a>
                <NavLink to="/events" className="nav-link" activeStyle={activeStyles}>
                  Past Events
                </NavLink>
                <NavLink to="/alumni" className="nav-link" activeStyle={activeStyles}>
                  Alumni
                </NavLink>
                <NavLink to="/shows" className="nav-link" activeStyle={activeStyles}>
                  Our Shows
                </NavLink>
              </DropdownContent>
            </Dropdown>
            {/* Other Topnav Links */}
            <NavLink to="/resources" className="nav-link" activeStyle={activeStyles}>
              Resources
            </NavLink>
            <NavLink to="/guests" className="nav-link" activeStyle={activeStyles}>
              Guests
            </NavLink>
            <NavHashLink to="/#contact" className="nav-link" activeStyle={activeStyles}>
              Contact
            </NavHashLink>
            <Button variant="red" path="/apply">
              How to Apply
            </Button>
          </LinkWrapper>
        </Hidden>
      </Row>
    </Nav>
  )
}

export default NavBar
