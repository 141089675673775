import WLTM from '../../assets/shows/WLTM.png'
import KIDREBEL from '../../assets/shows/KidRebel.png'
import KIRWBO from '../../assets/shows/KIRWBO.jpg'
import LACULTURE from '../../assets/shows/LaCulture.jpg'
import NTYA from '../../assets/shows/NTYA.jpg'
import TBTB from '../../assets/shows/TBTB.jpg'
import UNMAPPED from '../../assets/shows/Unmapped.jpg'
import SLIPPERY from '../../assets/shows/SlipperySlope.png'
import SPESHFX from '../../assets/shows/SpeshFX.jpg'
import INTHY from '../../assets/shows/INTHY.jpg'
import AAR from '../../assets/shows/AAR.png'
import TTTM from '../../assets/shows/TTTM.jpg'

export const SHOW_DATA = [
  {
    title: '2020 Fellows',
    shows: [
      {
        title: "abandoned: The All-American Ruins Podcast",
        image: AAR,
        link: 'https://www.blakepfeil.com/aar-podcast.html',
      },
      {
        title: "Talk Tull To Me",
        image: TTTM,
        link: 'https://podcasts.apple.com/us/podcast/talk-tull-to-me-a-weekly-jethro-tull-deep-dive/id1447314434',
      },
      {
        title: "It's Nice To Hear You",
        image: INTHY,
        link: 'https://www.itsnicetohearyou.com/',
      },

    ],
  },
  {
    title: '2019 Fellows',
    shows: [
  
      {
        title: 'This Book That Book',
        image: TBTB,
        link: 'https://cms.megaphone.fm/channel/thisbookthatbook',
      },
    ],
  },
  {
    title: '2018 Fellows',
    shows: [
  
      {
        title: "Keepin' It Real With Bridget O'Neill",
        image: KIRWBO,
        link: 'https://pod.link/1478467866',
      },
      {
        title: 'SpeshFX',
        image: SPESHFX,
        link:
          'https://pod.link/1470147693',
      },
      {
        title: 'Not That You Asked',
        image: NTYA,
        link: 'https://pod.link/1473208542',
      },
    ],
  },
]
