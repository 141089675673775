import React, { FC } from 'react';
import { SecondaryHero } from '../components/shared';
import AlumniGrid from '../components/Alumni/AlumniGrid';
import { ALUMNI_DATA } from '../components/Alumni/data';

const Alumni: FC = () => {
  return (
    <>
      <SecondaryHero>Alumni</SecondaryHero>
      <AlumniGrid data={ALUMNI_DATA} />
    </>
  );
};

export default Alumni;
