import React, { FC } from 'react';
import Hero from './Hero';
import Services from './Services';
import styled from 'styled-components';
import { useScreenClass } from 'react-grid-system';

const Section = styled.section<{ isDesktop: boolean }>`
  background-color: ${(props) => props.theme.colors.darkRed};
  /* Let the height be determined by its contents (Hero & Services). */
  height: auto;
  overflow: hidden;
`;

const HeroWrapper: FC = () => {
  const screenClass = useScreenClass();
  const isDesktop = ['lg', 'xl'].includes(screenClass);

  return (
    <Section isDesktop={isDesktop}>
      <Hero />
      {/* Reduce this margin if it's pushing your Services too far down. */}
      <div style={{ margin: isDesktop ? '3rem 2rem 0' : '2rem 1rem 0' }}>
        <Services />
      </div>
    </Section>
  );
};

export default HeroWrapper;
