import { nanoid } from 'nanoid'
import {
  BLAKE,
  HALLE,
  MARTHA,
  OMAMA,
  MANOLO,
  KAREEMA,
  JENNIFER,
  TANVI,
} from '../../assets/alumni'

export interface AlumniData {
  id: string
  name: string
  title: string
  image: string
  bio?: string
}

export const ALUMNI_DATA: AlumniData[] = [
  {
    id: nanoid(),
    name: 'Blake Pfeil',
    title: 'Producer',
    image: BLAKE,
    bio: `**Blake Pfeil** is the creator of *All-American Ruins*, a multi-media travelogue exploring abandoned spaces around the world and posing questions about American culture and history.

**Accolades** include:
- **2024**:
  - **Signal Awards**: *Best Experimental Podcast*, *Best Indie Podcast*, *Best Writing of an Individual Episode*, plus runner-up for *Most Innovative Listening Experience*
  - **Best History Podcast** at Melbourne WebFest, Parapod Podcast Awards, and PopCon Podcast Awards
  - Spotlighted in **Business Insider**, **People**, **Atlas Obscura**, and **Colorado Magazine**
  - Nominated for **Regional Podcast of the Year** at the 2024 **UK Press Gazette Future of Media Awards**
  - Nominated for **Best Indie Podcast** at the 2024 **Ambies**

Learn more on Blake’s [website](https://www.blakepfeil.com/allamericanruins.html).`,
  },
  {
    id: nanoid(),
    name: 'Jennifer Basset',
    title: 'Founder of Big Din',
    image: JENNIFER,
    bio: `**Jennifer Shin Bassett** is the founder of [**Big Din**](http://bigdinproductions.com/), a podcast production company.

**Production Credits**:
- **The Motherly Podcast** (Webby Awards winner, 2021; Honoree, 2022)
- **Time Out: A Fair Play Podcast**, **Talking Feds**, **Talking Feds: Women at the Table**, **The Healthy Baby Show**

Previously, Jennifer led global content initiatives at **Spotify**, startups, and ad agencies.`,
  },
  {
    id: nanoid(),
    name: 'Tanvi Kumar',
    title: 'Podcast Producer',
    image: TANVI,
    bio: `**Tanvi Kumar** was an Audio Podcast Fellow from **2020–2021**, developing *Don't Look Away*, a show about American immigrant detention centers.

After the fellowship, Tanvi:
- Continued work on *Don't Look Away* (selected by the Rough Cut Collective).
- Joined the [**AIR New Voices Scholars**](https://airmedia.org/community/spotlight/tanvi-kumar) program in 2021.
- Became a Production Fellow at [**Live Wire Radio**](https://www.livewireradio.org/).
- Currently producing a new project for [**Outside Inc.**](https://www.outsideinc.com/) and [**House of Pod**](https://www.houseofpod.org/) called *The Daily Rally.*
- Featured as a participant in the [**Nickelodeon Writing Program**](https://www.animationmagazine.net/2024/04/nickelodeon-writing-program-announces-2024-participants-exclusive/), as covered by Animation Magazine.`,
  },
  {
    id: nanoid(),
    name: 'Halle Hewitt',
    title: 'Producer',
    image: HALLE,
    bio: `**Halle Hewitt** is a production assistant on StoryCorps' *One Small Step*. She also produces the upcoming podcast [**CHROMA**](https://www.instagram.com/chromapodcast/), a **saturated** look at how people relate to colors.

**Additional Work**:
- Pitched and helped produce “[Returning Home: Three Oneida Children Find a Final Resting Place](https://storycorps.org/stories/returning-home-three-oneida-children-find-a-final-resting-place/)” for StoryCorps and *NPR's Morning Edition*.`,
  },
  {
    id: nanoid(),
    name: 'Omama Othman',
    title: 'Writer & Producer',
    image: OMAMA,
    bio: `**Omama Othman** is an audiovisual translator, writer, and audio producer in Doha, Qatar. Her work focuses on **education**, **accessibility**, and **migrants' rights** in the Arab Gulf.

She's developing **Ballads of Others**, a podcast exploring the places we leave that never leave us—uncovering stories of people othered by their own communities. Omama’s [**first audio piece**](https://vimeo.com/showcase/8330067/video/533456158) was an Official Select at **On Air Fest 2021**.`,
  },
  {
    id: nanoid(),
    name: 'Martha Pichey',
    title: 'Playwright & Producer',
    image: MARTHA,
    bio: `**Martha Pichey** created *WATERMARKS*, inspired by her fascination with whaling and the bold women who joined whaling voyages in the 1800s. She’s also writing *I AM A SHIP*.

**Highlights**:
- Her first play, **ASHES & INK**, was produced by Nora’s Playhouse in 2022.
- Member of the Playwrights and Directors Workshop at The Actors Studio.
- Freelance writer for publications from *Vanity Fair* to *The New York Times*.

Visit [**marthapichey.com**](https://marthapichey.com) for more details.`,
  },
  {
    id: nanoid(),
    name: 'Kareema Bee',
    title: 'Founder of The HiveMind Unified',
    image: KAREEMA,
    bio: `**Kareema Bee** is a content creator from NYC with a background as a **producer** for major TV, film, and digital productions. A **writer** and **actress**, she has been a semi-finalist in notable network writing fellowships.

She founded **The HiveMind Unified** to support people of color advancing in entertainment. Learn more at [**kareemabee.com**](http://kareemabee.com/).`,
  },
  {
    id: nanoid(),
    name: 'Manolo Morales',
    title: 'Podcast Producer',
    image: MANOLO,
    bio: `**Manolo Morales** is a graduate of the **Craig Newmark Graduate School of Journalism**, with previous internships at **Latino USA** and **KALW**. He was also an **AIR New Voices** scholar in 2020.

**Recent Work**:
- Produced a Spanish-language story for *CUIR: Historias Disidentes*.
- Currently a producer at **WitnessDocs**.
- Some pieces include “[Nuestra Diva, Lorena Borjas](https://estonoesradio.mx/programas/cuir/temporada-1/nuestra-diva-lorena-borjas)” and *Atlas Obscura*.`,
  },
]
