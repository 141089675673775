import React, { FC } from 'react'
import { useScreenClass } from 'react-grid-system'
import styled from 'styled-components'

interface EventData {
  description: string
  image: string
}

interface GridProps {
  data: EventData[]
}

const IGrid = styled.div<{ isDesktop: boolean }>`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: ${(props) => (props.isDesktop ? '2rem' : '1rem')};
  margin: ${(props) => (props.isDesktop ? '4rem auto' : '2rem auto')};
  max-width: 1200px;
  padding: 0 1rem;
`

const Section = styled.section<{ isDesktop: boolean }>`
  background-color: #f0f2f5;
  padding: ${(props) => (props.isDesktop ? '6rem 2rem' : '4rem 1rem')};
`

const EventCard = styled.div`
  position: relative;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.4s ease, box-shadow 0.4s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);

  &:hover {
    transform: translateY(-8px) scale(1.02);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  }
`

const EventImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8));
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 1.5rem;
  text-align: center;
  font-size: 1.1rem;
  font-weight: 500;
  color: #fff;
  opacity: 0;
  transition: opacity 0.4s ease-in-out;

  /* When the parent card is hovered, reveal the overlay */
  ${EventCard}:hover & {
    opacity: 1;
  }
`

const Grid: FC<GridProps> = ({ data }) => {
  const screenClass = useScreenClass()
  const isDesktop = ['lg', 'xl'].includes(screenClass)

  return (
    <Section isDesktop={isDesktop}>
      <IGrid isDesktop={isDesktop}>
        {data.map((event, index) => (
          <EventCard key={index}>
            <EventImage src={event.image} alt="Event" />
            <Overlay>{event.description}</Overlay>
          </EventCard>
        ))}
      </IGrid>
    </Section>
  )
}

export default Grid
